import React from "react";
import Header from "../components/Banner";
import Features from "../components/Features";
import FeaturedProduct from "../components/FeaturedProduct";
import NewArrivals from "../components/NewArrivals";
// import Blog from "../components/Blog";
// import Customer from "../components/Customer";
import Brands from "../components/Brands";




const Home = () => {
  return (
    <div>
      <div class="page-wrapper">
        <Header />
        <Features />
        <FeaturedProduct />
        <NewArrivals />
        {/* <Blog /> */}
        {/* <Customer /> */}
        <Brands />
        {/* <Shoplist/> */}
       
      </div>
    </div>
  );
};

export default Home;
