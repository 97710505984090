import axios from "axios";


// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import { useState, useEffect } from "react";

const api = "https://ea.allgetz.com/api/v1/site";

const FeaturedProduct = () => {
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    getFeatured();
  }, []);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await axios.get(
  //         api + "/all-featured-products"
  //       );
  //       setFeatured(response.data);
  //        console.log(response.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   fetchData();
  // }, []);
  async function getFeatured() {
    const response = await axios.get(api + "/all-featured-products");
    console.log(response);
    setFeatured(response.data.data.products);
  }
  return (
    <div>
      <div className="bg-light-2 pt-6 pb-6 featured">
        <div className="container-fluid">
          <div className="heading heading-center mb-3">
            <h2 className="title">FEATURED PRODUCTS</h2>

            <ul className="nav nav-pills justify-content-center" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="featured-women-link"
                  data-toggle="tab"
                  href="#featured-women-tab"
                  role="tab"
                  aria-controls="featured-women-tab"
                  aria-selected="true"
                >
                  Clothing
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="featured-men-link"
                  data-toggle="tab"
                  href="#featured-men-tab"
                  role="tab"
                  aria-controls="featured-men-tab"
                  aria-selected="false"
                >
                  Mens Clothing
                </a>
              </li>
            </ul>
          </div>

          <div style={{ display: "flex", flexDirection: "row",justifyContent:"space-between" }}>
            {featured.map((item, i) => {
            
              return (
                <div className="product product-7 text-center" key={i}>
                  <figure className="product-media">
                    <a href="blog.html">
                      <img style={{height:"300px",objectFit:"cover"}}
                        src={
                          "https://ea.allgetz.com/uploads/products/" +
                          item.image
                        }
                        alt=""
                        className="product-image"
                      />
                      <img
                      style={{height:"300px",objectFit:"cover"}}
                        src="assets/images/demos/demo-7/products/product-1-1.jpg"
                        alt=""
                        className="product-image-hover"
                      />
                    </a>

                    <div className="product-action-vertical">
                      <a
                        href="blog.html"
                        className="btn-product-icon btn-wishlist btn-expandable"
                      >
                        <span>add to wishlist</span>
                      </a>
                      <a
                        href="popup/quickView.html"
                        className="btn-product-icon btn-quickview"
                        title="Quick view"
                      >
                        <span></span>
                      </a>
                    </div>

                    <div className="product-action">
                      <a href="blog.html" className="btn-product btn-cart">
                        <span>add to cart</span>
                      </a>
                    </div>
                  </figure>

                  <div className="product-body">
                    <h3 className="product-title">
                      <a href="product.html">{item.name}</a>
                    </h3>
                    <div className="product-price">{item.price}</div>
                   
                  
                    </div>
                  </div>
         
              );
            })}

            {/* <div className="product product-7 text-center">
              <figure className="product-media">
                <a href="product.html">
                  <img
                    src="assets/images/demos/demo-7/products/product-1-2.jpg"
                    alt=""
                    className="product-image"
                  />
                  <img
                    src="assets/images/demos/demo-7/products/product-1-2.jpg"
                    alt=""
                    className="product-image-hover"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span></span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <h3 className="product-title">
                  <a href="product.html">Biker jacket</a>
                </h3>
                <div className="product-price">$120.99</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "20%" }}></div>
                  </div>
                  <span className="ratings-text">( 2 Reviews )</span>
                </div>

                <div className="product-nav product-nav-dots">
                  <a
                    href="blog.html"
                    className="active"
                    style={{ backgroundColor: "#cc3333" }}
                  >
                    <span className="sr-only">Color name</span>
                  </a>
                  <a href="blog.html" style={{ backgroundColor: "#cc3333" }}>
                    <span className="sr-only">Color name</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="product product-7 text-center">
              <figure className="product-media">
                <a href="product.html">
                  <img
                    src="assets/images/demos/demo-7/products/product-2-1.jpg"
                    alt=""
                    className="product-image"
                  />
                  <img
                    src="assets/images/demos/demo-7/products/product-2-1.jpg"
                    alt=""
                    className="product-image-hover"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <h3 className="product-title">
                  <a href="product.html">Sandals</a>
                </h3>
                <div className="product-price">$70.00</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "60%" }}></div>
                  </div>
                  <span className="ratings-text">( 4 Reviews )</span>
                </div>
              </div>
            </div>
            <div className="product product-7 text-center">
              <figure className="product-media">
                <span className="product-label label-circle label-sale">
                  Sale
                </span>
                <a href="product.html">
                  <img
                    src="assets/images/demos/demo-7/products/product-3-1.jpg"
                    alt=""
                    className="product-image"
                  />
                  <img
                    src="assets/images/demos/demo-7/products/product-3-1.jpg"
                    alt=""
                    className="product-image-hover"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>

                <div
                  className="deal-countdown offer-countdown"
                  data-until="+11d"
                ></div>
              </figure>

              <div className="product-body">
                <h3 className="product-title">
                  <a href="product.html">Super Skinny High</a>
                </h3>
                <div className="product-price">
                  <span className="new-price">$190.00</span>
                  <span className="old-price">$310.00</span>
                </div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "40%" }}></div>
                  </div>
                  <span className="ratings-text">( 4 Reviews )</span>
                </div>
              </div>
            </div>
            <div className="product product-7 text-center">
              <figure className="product-media">
                <span className="product-label label-circle label-top">
                  Top
                </span>
                <a href="product.html">
                  <img
                    src="assets/images/demos/demo-7/products/product-3-1.jpg"
                    alt=""
                    className="product-image"
                  />
                  <img
                    src="assets/images/demos/demo-7/products/product-3-2.jpg"
                    alt=""
                    className="product-image-hover"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <h3 className="product-title">
                  <a href="product.html">Short wrap dress</a>
                </h3>
                <div className="product-price">$80.00</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div
                      className="ratings-val"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <span className="ratings-text">( 6 Reviews )</span>
                </div>
              </div>
            </div>
            <div className="product product-7 text-center">
              <figure className="product-media">
                <a href="product.html">
                  <img
                    src="assets/images/demos/demo-7/products/product-4-1.jpg"
                    alt=""
                    className="product-image"
                  />
                  <img
                    src="assets/images/demos/demo-7/products/product-4-1.jpg"
                    alt=""
                    className="product-image-hover"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <h3 className="product-title">
                  <a href="product.html">Backpack</a>
                </h3>
                <div className="product-price">$60.00</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "20%" }}></div>
                  </div>
                  <span className="ratings-text">( 2 Reviews )</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProduct;
