import React from "react";
import MyTopBar from "./MyTopBar";
import Navbar from "./Navbar";

const Header = () => {
  return (
    <div>
      <header class="header header-7">
        <MyTopBar />
        <Navbar />
      </header>
    </div>
  );
};

export default Header;
