import axios from "axios";
import { useEffect,useState} from "react";
import { useParams } from "react-router-dom";

const api = "https://ea.allgetz.com/api/v1/site";
const Shop = () => {
  const [product, setProduct] = useState([]);

  const { id } = useParams();

  console.log(id);

  useEffect(() => {
    async function getProduct() {
      const response = await axios.get(api + "/product/" + id);
      console.log(response);
      setProduct(response.data.data.product);
    }
    getProduct();
  }, [id]);

  return (
    <div className="page-wrapper shop">
      <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container d-flex align-items-center">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="blog.html">Products</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Default
              </li>
            </ol>

            <nav className="product-pager ml-auto" aria-label="Product">
              <a
                className="product-pager-link product-pager-prev"
                href="blog.html"
                aria-label="Previous"
                tabIndex="-1"
              >
                <i className="icon-angle-left"></i>
                <span>Prev</span>
              </a>

              <a
                className="product-pager-link product-pager-next"
                href="blog.html"
                aria-label="Next"
                tabIndex="-1"
              >
                <span>Next</span>
                <i className="icon-angle-right"></i>
              </a>
            </nav>
          </div>
        </nav>
      </main>
      <div className="page-content">
        <div className="container">
          <div className="product-details-top">
            <div className="row">
              <div className="col-md-6">
                <div className="product-gallery product-gallery-vertical">
                  <div className="row">
                    <figure className="product-main-image">
                      <img
                        id="product-zoom"
                        style={{ width: "420px", height: "400px" }}
                        src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                        data-zoom-image="assets/images/demos/demo-7/banners/banner-5.jpg"
                        alt=""
                      />

                      <a
                        href="blog.html"
                        id="btn-product-gallery"
                        className="btn-product-gallery"
                      >
                        <i className="icon-arrows"></i>
                      </a>
                    </figure>

                    <div
                      id="product-zoom-gallery"
                      className="product-image-gallery"
                    >
                      <a
                        className="product-gallery-item active"
                        href="blog.html"
                        data-image="assets/images/products/single/1.jpg"
                        data-zoom-image="assets/images/products/single/1-big.jpg"
                      >
                        <img style={{height:"100px",width:"120px"}}
                          src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                          alt=""
                        />
                      </a>

                      <a
                        className="product-gallery-item"
                        href="blog.html"
                        data-image="assets/images/products/single/2.jpg"
                        data-zoom-image="assets/images/products/single/2-big.jpg"
                      >
                        <img style={{height:"100px",width:"120px"}}
                          src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                          alt=""
                        />
                      </a>

                      <a
                        className="product-gallery-item"
                        href="blog.html"
                        data-image="assets/images/products/single/3.jpg"
                        data-zoom-image="assets/images/products/single/3-big.jpg"
                      >
                       <img style={{height:"100px",width:"120px"}}
                          src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                          alt=""
                        />
                      </a>

                      <a
                        className="product-gallery-item"
                        href="blog.html"
                        data-image="assets/images/products/single/4.jpg"
                        data-zoom-image="assets/images/products/single/4-big.jpg"
                      >
                       <img style={{height:"100px",width:"120px"}}
                          src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="product-details">
                  <h1 className="product-title">
                  {product.name}
                  </h1>

                  <div className="ratings-container">
                    <div className="ratings">
                      <div
                        className="ratings-val"
                        style={{ width: "80%" }}
                      ></div>
                    </div>
                    <a
                      className="ratings-text"
                      href="#product-review-link"
                      id="review-link"
                    >
                      ( 2 Reviews )
                    </a>
                  </div>

                  <div className="product-price"> NRS {product.price}</div>

                  <div className="product-content">
                    <p>
                      {product.description}
                    </p>
                  </div>

                  <div className="details-filter-row details-row-size">
                    <label>Color:</label>

                    <div className="product-nav product-nav-thumbs">
                      <a href="blog.html" className="active">
                        <img
                          src="assets/images/demos/demo-7/banners/banner-5.jpg"
                          alt=""
                        />
                      </a>
                      <a href="blog.html">
                        <img
                          src="assets/images/demos/demo-7/banners/banner-5.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>

                  <div className="details-filter-row details-row-size">
                    <label htmlFor="size">Size:</label>
                    <div className="select-custom">
                      <select name="size" id="size" className="form-control">
                        <option value="#" defaultValue="selected" readOnly>
                          Select a size
                        </option>
                        <option value="s">Small</option>
                        <option value="m">Medium</option>
                        <option value="l">Large</option>
                        <option value="xl">Extra Large</option>
                      </select>
                    </div>

                    <a href="blog.html" className="size-guide">
                      <i className="icon-th-list"></i>size guide
                    </a>
                  </div>

                  <div className="details-filter-row details-row-size">
                    <label htmlFor="qty">Qty:</label>
                    <div className="product-details-quantity">
                      <input
                        type="number"
                        id="qty"
                        className="form-control"
                        value="1"
                        min="1"
                        max="10"
                        step="1"
                        data-decimals="0"
                        required
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="product-details-action">
                    <a href="blog.html" className="btn-product btn-cart">
                      <span>add to cart</span>
                    </a>

                    <div className="details-action-wrapper">
                      <a
                        href="blog.html"
                        className="btn-product btn-wishlist"
                        title="Wishlist"
                      >
                        <span>Add to Wishlist</span>
                      </a>
                      <a
                        href="blog.html"
                        className="btn-product btn-compare"
                        title="Compare"
                      >
                        <span>Add to Compare</span>
                      </a>
                    </div>
                  </div>

                  <div className="product-details-footer">
                    <div className="product-cat">
                      <span>Category:</span>
                      <a href="blog.html">Women</a>,
                      <a href="blog.html">Dresses</a>,
                      <a href="blog.html">Yellow</a>
                    </div>

                    <div className="social-icons social-icons-sm">
                      <span className="social-label">Share:</span>
                      <a
                        href="blog.html"
                        className="social-icon"
                        title="Facebook"
                        target="_blank"
                      >
                        <i className="icon-facebook-f"></i>
                      </a>
                      <a
                        href="blog.html"
                        className="social-icon"
                        title="Twitter"
                        target="_blank"
                      >
                        <i className="icon-twitter"></i>
                      </a>
                      <a
                        href="blog.html"
                        className="social-icon"
                        title="Instagram"
                        target="_blank"
                      >
                        <i className="icon-instagram"></i>
                      </a>
                      <a
                        href="blog.html"
                        className="social-icon"
                        title="Pinterest"
                        target="_blank"
                      >
                        <i className="icon-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="product-details-tab">
            <ul className="nav nav-pills justify-content-center" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="product-desc-link"
                  data-toggle="tab"
                  href="#product-desc-tab"
                  role="tab"
                  aria-controls="product-desc-tab"
                  aria-selected="true"
                >
                  Description
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="product-info-link"
                  data-toggle="tab"
                  href="#product-info-tab"
                  role="tab"
                  aria-controls="product-info-tab"
                  aria-selected="false"
                >
                  Additional information
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="product-shipping-link"
                  data-toggle="tab"
                  href="#product-shipping-tab"
                  role="tab"
                  aria-controls="product-shipping-tab"
                  aria-selected="false"
                >
                  Shipping & Returns
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="product-review-link"
                  data-toggle="tab"
                  href="#product-review-tab"
                  role="tab"
                  aria-controls="product-review-tab"
                  aria-selected="false"
                >
                  Reviews (2)
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="product-desc-tab"
                role="tabpanel"
                aria-labelledby="product-desc-link"
              >
                <div className="product-desc-content">
                  <h3>Product Information</h3>
                  <p>
                    This is a text.You can add anything in it.this is the second
                    test.you can add anything againThis is a text.You can add
                    anything in it.this is the second test.you can add anything
                    againThis is a text.You can add anything in it.this is the
                    second test.you can add anything again{" "}
                  </p>
                  <ul>
                    <li>this is the second test.you can add anything again</li>
                    <li>this is the second test.you can add anything again</li>
                    <li>this is the second test.you can add anything again</li>
                  </ul>

                  <p>
                    This is a text.You can add anything in it.this is the second
                    test.you can add anything againThis is a text.You can add
                    anything in it.this is the second test.you can add anything
                    againThis is a text.You can add anything in it.this is the
                    second test.you can add anything again{" "}
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="product-info-tab"
                role="tabpanel"
                aria-labelledby="product-info-link"
              >
                <div className="product-desc-content">
                  <h3>Information</h3>
                  <p>
                    {" "}
                    This is a text.You can add anything in it.this is the second
                    test.you can add anything againThis is a text.You can add
                    anything in it.this is the second test.you can add anything
                    againThis is a text.You can add anything in it.this is the
                    second test.you can add anything again{" "}
                  </p>

                  <h3>Fabric & care</h3>
                  <ul>
                    <li>Faux suede fabric</li>
                    <li>Gold tone metal hoop handles.</li>
                    <li>RI branding</li>
                    <li>Snake print trim interior </li>
                    <li>Adjustable cross body strap</li>
                    <li>
                      {" "}
                      Height: 31cm; Width: 32cm; Depth: 12cm; Handle Drop: 61cm
                    </li>
                  </ul>

                  <h3>Size</h3>
                  <p>one size</p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="product-shipping-tab"
                role="tabpanel"
                aria-labelledby="product-shipping-link"
              >
                <div className="product-desc-content">
                  <h3>Delivery & returns</h3>
                  <p>
                    We deliver to over 100 countries around the world. For full
                    details of the delivery options we offer, please view our{" "}
                    <a href="blog.html">Delivery information</a>
                    <br />
                    This is a text.You can add anything in it.this is the second
                    test.you can add anything againThis is a text.You can add
                    anything in it.this is the second test.you can add anything
                    againThis is a text.You can add anything in it.this is the
                    second test.you can add anything again{" "}
                    <a href="blog.html">Returns information</a>
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="product-review-tab"
                role="tabpanel"
                aria-labelledby="product-review-link"
              >
                <div className="reviews">
                  <h3>Reviews (2)</h3>
                  <div className="review">
                    <div className="row no-gutters">
                      <div className="col-auto">
                        <h4>
                          <a href="blog.html"> J.</a>
                        </h4>
                        <div className="ratings-container">
                          <div className="ratings">
                            <div
                              className="ratings-val"
                              style={{ width: " 80%" }}
                            ></div>
                          </div>
                        </div>
                        <span className="review-date">6 days ago</span>
                      </div>
                      <div className="col">
                        <h4>Good, perfect size</h4>

                        <div className="review-content">
                          <p>
                            {" "}
                            This is a text.You can add anything in it.this is
                            the second test.you can add anything againThis is a
                            text.You can add anything in it.this is the second
                            test.you can add anything againThis is a text.You
                            can add anything in it.this is the second test.you
                            can add anything again
                          </p>
                        </div>

                        <div className="review-action">
                          <a href="blog.html">
                            <i className="icon-thumbs-up"></i>Helpful (2)
                          </a>
                          <a href="blog.html">
                            <i className="icon-thumbs-down"></i>Unhelpful (0)
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="review">
                    <div className="row no-gutters">
                      <div className="col-auto">
                        <h4>
                          <a href="blog.html">John Doe</a>
                        </h4>
                        <div className="ratings-container">
                          <div className="ratings">
                            <div
                              className="ratings-val"
                              style={{ width: " 100%" }}
                            ></div>
                          </div>
                        </div>
                        <span className="review-date">5 days ago</span>
                      </div>
                      <div className="col">
                        <h4>Very good</h4>

                        <div className="review-content">
                          <p>
                            {" "}
                            This is a text.You can add anything in it.this is
                            the second test.you can add anything againThis is a
                            text.You can add anything in it.this is the second
                            test.you can add anything againThis is a text.You
                            can add anything in it.this is the second test.you
                            can add anything again
                          </p>
                        </div>

                        <div className="review-action">
                          <a href="blog.html">
                            <i className="icon-thumbs-up"></i>Helpful (0)
                          </a>
                          <a href="blog.html">
                            <i className="icon-thumbs-down"></i>Unhelpful (0)
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className="title text-center mb-4">You May Also Like</h2>

          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div className="product product-7 text-center">
              <figure className="product-media">
                <span className="product-label label-new">New</span>
                <a href="product.html">
                  <img
                   src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                    alt=""
                    className="product-image"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-compare"
                    title="Compare"
                  >
                    <span>Compare</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <div className="product-cat">
                  <a href="blog.html">Women</a>
                </div>
                <h3 className="product-title">
                  <a href="product.html">
                    Brown waist <br />
                    pencil skirt
                  </a>
                </h3>
                <div className="product-price">$60.00</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div
                      className="ratings-val"
                      style={{ width: "20px" }}
                    ></div>
                  </div>
                  <span className="ratings-text">( 2 Reviews )</span>
                </div>

                <div className="product-nav product-nav-thumbs">
                  <a href="blog.html" className="active">
                    <img
                      src="assets/images/demos/demo-7/banners/banner-5.jpg"
                      alt=""
                    />
                  </a>
                  <a href="blog.html">
                    <img
                      src="assets/images/demos/demo-7/banners/banner-5.jpg"
                      alt="product desc"
                    />
                  </a>

                  <a href="blog.html">
                    <img
                      src="assets/images/demos/demo-7/banners/banner-5.jpg"
                      alt="product desc"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="product product-7 text-center">
              <figure className="product-media">
                <span className="product-label label-out">Out of Stock</span>
                <a href="product.html">
                  <img
                    src="assets/images/demos/demo-7/banners/banner-5.jpg"
                    alt=""
                    className="product-image"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-compare"
                    title="Compare"
                  >
                    <span>Compare</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <div className="product-cat">
                  <a href="blog.html">Jackets</a>
                </div>
                <h3 className="product-title">
                  <a href="product.html">Khaki utility boiler jumpsuit</a>
                </h3>
                <div className="product-price">
                  <span className="out-price">$120.00</span>
                </div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "80%" }}></div>
                  </div>
                  <span className="ratings-text">( 6 Reviews )</span>
                </div>
              </div>
            </div>

            <div className="product product-7 text-center">
              <figure className="product-media">
                <span className="product-label label-top">Top</span>
                <a href="product.html">
                  <img
                   src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                    alt=""
                    className="product-image"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-compare"
                    title="Compare"
                  >
                    <span>Compare</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <div className="product-cat">
                  <a href="blog.html">Shoes</a>
                </div>
                <h3 className="product-title">
                  <a href="product.html">
                    Lights brown studded Wide fit wedges
                  </a>
                </h3>
                <div className="product-price">$110.000</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "80%" }}></div>
                  </div>
                  <span className="ratings-text">( 1 Reviews )</span>
                </div>

                <div className="product-nav product-nav-thumbs">
                  <a href="blog.html" className="active">
                    <img
                     src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                      alt=""
                    />
                  </a>
                  <a href="blog.html">
                    <img
                      src="assets/images/demos/demo-7/banners/banner-5.jpg"
                      alt=""
                    />
                  </a>

                  <a href="blog.html">
                    <img
                      src="assets/images/demos/demo-7/banners/banner-5.jpg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="product product-7 text-center">
              <figure className="product-media">
                <a href="product.html">
                  <img
                    src="assets/images/demos/demo-7/banners/banner-5.jpg"
                    alt=""
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-compare"
                    title="Compare"
                  >
                    <span>Compare</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <div className="product-cat">
                  <a href="blog.html">Jumpers</a>
                </div>
                <h3 className="product-title">
                  <a href="product.html">Yellow button front tea top</a>
                </h3>
                <div className="product-price">$56.00</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "0%" }}></div>
                  </div>
                  <span className="ratings-text">( 0 Reviews )</span>
                </div>
              </div>
            </div>

            <div className="product product-7 text-center">
              <figure className="product-media">
                <a href="product.html">
                <img
                   src={product.gallery ? "https://ea.allgetz.com//uploads/products/"+product?.gallery[0] : ''}
                    alt=""
                    className="product-image"
                  />
                </a>

                <div className="product-action-vertical">
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-wishlist btn-expandable"
                  >
                    <span>add to wishlist</span>
                  </a>
                  <a
                    href="popup/quickView.html"
                    className="btn-product-icon btn-quickview"
                    title="Quick view"
                  >
                    <span>Quick view</span>
                  </a>
                  <a
                    href="blog.html"
                    className="btn-product-icon btn-compare"
                    title="Compare"
                  >
                    <span>Compare</span>
                  </a>
                </div>

                <div className="product-action">
                  <a href="blog.html" className="btn-product btn-cart">
                    <span>add to cart</span>
                  </a>
                </div>
              </figure>

              <div className="product-body">
                <div className="product-cat">
                  <a href="blog.html">Jeans</a>
                </div>
                <h3 className="product-title">
                  <a href="product.html">Blue utility pinafore denim dress</a>
                </h3>
                <div className="product-price">$76.00</div>
                <div className="ratings-container">
                  <div className="ratings">
                    <div className="ratings-val" style={{ width: "20%" }}></div>
                  </div>
                  <span className="ratings-text">( 2 Reviews )</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
