import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
const api = "https://ea.allgetz.com/api/v1/site";
const Navbar = () => {
  const [cartItem, setCartItem] = useState([]);
  const [total, setTotal] = useState(0);

  async function getCart() {
    const response = await axios.get(api + "/cart/all-products", {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("loginToken"),
      },
    });
    setCartItem(response.data.data.carts);
    setTotal(response.data.data.total);
  }

  useEffect(() => {
    getCart();
  }, []);

  return (
    <div className="header-middle sticky-header">
      <div className="container-fluid">
        <div className="header-left">
          <button className="mobile-menu-toggler">
            <span className="sr-only">Toggle mobile menu</span>
            <i className="icon-bars"></i>
          </button>
          <a href="index.html" className="logo">
            <Link to="/">
              {" "}
              <img
                src="assets/images/demos/demo-7/logo1.png"
                alt="logo"
                width="105"
                height="25"
              />
            </Link>
          </a>
          <nav className="main-nav">
            <ul className="menu">
              <li className="megamenu-container active">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/shoplist">Shop</Link>
              </li>
              <li></li>
              <li>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <ul></ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="header-right">
          <div className="header-search header-search-extended header-search-visible">
            <a href="blog.html" className="search-toggle" role="button">
              <i className="icon-search"></i>
            </a>
            <form action="#" method="get">
              <div className="header-search-wrapper search-wrapper-wide">
                <label for="q" className="sr-only">
                  Search
                </label>
                <input
                  type="search"
                  className="form-control"
                  name="q"
                  id="q"
                  placeholder="Search product ..."
                  required
                />
                <button className="btn btn-primary" type="submit">
                  <i className="icon-search"></i>
                </button>
              </div>
            </form>
          </div>

          <div className="dropdown cart-dropdown">
            <a
              href="blog.html"
              className="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-display="static"
            >
              <i className="icon-shopping-cart"></i>
              <span className="cart-count">{cartItem.length}</span>
            </a>

            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-cart-products">
                {cartItem?.map((item) => (
                  <div className="product" key={item.id}>
                    <div className="product-cart-details">
                      <h4 className="product-title">
                        <a href="product.html">
                          {item.product.name}
                        </a>
                      </h4>

                      <span className="cart-product-info">
                        <span className="cart-product-qty">{item.quantity}</span>x ${item.product.price}
                      </span>
                    </div>

                    <figure className="product-image-container">
                      <a href="product.html" className="product-image">
                        <img
                          src="assets/images/products/cart/product-1.jpg"
                          alt="product"
                        />
                      </a>
                    </figure>
                    <a
                      href="blog.html"
                      className="btn-remove"
                      title="Remove Product"
                    >
                      <i className="icon-close"></i>
                    </a>
                  </div>
                ))}
              </div>

              <div className="dropdown-cart-total">
                <span>Total</span>

                <span className="cart-total-price">{total}</span>
              </div>

              <div className="dropdown-cart-action">
                <p className="btn btn-outline-primary-2">
                  <Link to="/cart">View Cart</Link>
                </p>
                <p className="btn btn-outline-primary-2">
                  <Link to="/checkout">CheckOut</Link>
                  <i className="icon-long-arrow-right"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
