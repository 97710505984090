import axios from "axios";
import React, { useState, useEffect } from "react";
import image from "../../src/assets/images/post-5.jpg";

const authURL = "https://ea.allgetz.com/api/v1/site";

const Who = () => {
  const [who, setWho] = useState({});
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getWhoSection = () => {
      // setLoading(true);
      const name = "About";

      const formData = new FormData();
      formData.append("name", name);
      axios
        .post(authURL + "/cms-payload-system/get", formData)
        .then((response) => {
          console.log(response, "who");
          // setLoading(false);
          const data = JSON.parse(
            response.data.data.cms_payload_system.payload
          );

          setWho(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getWhoSection();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <h2 className="title">Who We are</h2>
          <div dangerouslySetInnerHTML={{ __html: who.who }} />
        </div>

        <div className="col-lg-6">
          <img alt="" src={image} />
        </div>
      </div>
    </div>
  );
};

export default Who;
