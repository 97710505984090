import React from 'react'

const Faq = () => {
  return (
    <div>
      <div class="content">
<div>
  <input type="checkbox" id="question1" name="q"  class="questions"/>
  <div class="plus">+</div>
  <label for="question1" class="question">
    This is the question that will be asked?
  </label>
  <div class="answers">
This is the answer of the question.. keep it short.</div>
</div>

<div>
  <input type="checkbox" id="question2" name="q" class="questions"/>
  <div class="plus">+</div>
  <label for="question2" class="question">
    Short?
  </label>
  <div class="answers">
    short!
  </div>
</div>
  
<div>
  <input type="checkbox" id="question3" name="q" class="questions"/>
  <div class="plus">+</div>
  <label for="question3" class="question">
    Keep answers short. But in case of...If the question is long, the text wraps.  
  </label>
  <div class="answers">
    This is the answer!
  </div>
</div>
</div>
    </div>
  )
}

export default Faq