import {useMemo} from 'react';


export default function Pagination(props) {
  const pages = useMemo(() => props.totalData ? Math.ceil(props.totalData / 8) : 1, [props.totalData]);

  return (
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        {props.index > 1 &&
        <li class="page-item">
          <a
            class="page-link page-link-prev"
            href={() => false}
            onClick={() => {
              props.setIndex(currentValue => currentValue - 1);
            }}
            aria-label="Previous"
            tabindex="-1"
            aria-disabled="true"
          >
            <span aria-hidden="true">
              <i class="icon-long-arrow-left"></i>
            </span>
            Prev
          </a>
        </li>
}
        {
        [...Array(pages)].map((_, index) => (
        
            <li class={`page-item ${props.index === index + 1 && 'active'}`} aria-current="page">
            <a class="page-link" href={() => false} onClick={() => {
              props.setIndex(index + 1);
            }}>
              {index + 1}
            </a>
          </li>
))}
        <li class="page-item-total">of {pages}</li>
        { props.index < pages &&
        <li class="page-item">
          <a
            class="page-link page-link-next"
            href="/#"
            aria-label="Next"
            onClick={(e) => {
              e.preventDefault();
              props.setIndex(currentValue => currentValue + 1)}
            }
          >
            Next{" "}
            <span aria-hidden="true">
              <i class="icon-long-arrow-right"></i>
            </span>
          </a>
        </li>
}
      </ul>
    </nav>
  );
}
