import { http } from "./http";

const getProducts = async (limit = 9, page = 1) =>
  await http
    .get(`all-products?limit=${limit}&page=${page}`)
    .then((res) => res.data);

export const Product = {
  getProducts,
};
