import React, { useState, useEffect } from "react";
import axios from "axios";

const authURL = "https://ea.allgetz.com/api/v1/site";

const Mission = () => {
  const [aboutData, setAboutData] = useState({});
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAboutSection = () => {
      // setLoading(true);
      const name = "About";

      const formData = new FormData();
      formData.append("name", name);
      axios
        .post(authURL + "/cms-payload-system/get", formData)
        .then((response) => {
          console.log(response, "about");
          // setLoading(false);
          const data = JSON.parse(
            response.data.data.cms_payload_system.payload
          );

          setAboutData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getAboutSection();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <h2 className="title">{aboutData.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: aboutData.vision }} />
        </div>

        <div className="col-lg-6">
          <h2 className="title">{aboutData.title1}</h2>
          <div dangerouslySetInnerHTML={{ __html: aboutData.mission }} />
        </div>
      </div>
    </div>
  );
};

export default Mission;
