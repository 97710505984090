import React from 'react'

const Refund = () => {
  return (
    <div className='refund'>Issuance of Refunds
    <br/>If your product is eligible for a refund, you can choose your preferred refund method based on the table below. The shipping fee is refunded along with the amount paid for your returned product.
    <br/>
    <br/>The time required to complete a refund depends on the refund method you have selected. Once we have received your product (2-3 working days) and it has undergone a quality control (1-2 working days), the expected refund processing times are as follows:</div>
  )
}

export default Refund