import React, { useState, useEffect } from "react";
import axios from "axios";
import partnerImage from "../assets/images/8.png";
const authURL = "https://ea.allgetz.com/api/v1/site";

const States = () => {
  const [partners, setPartners] = useState({});
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPartnersSection = () => {
      // setLoading(true);
      const name = "Partners";

      const formData = new FormData();
      formData.append("name", name);
      axios
        .post(authURL + "/cms-payload-system/get", formData)
        .then((response) => {
          console.log(response, "Partners");
          // setLoading(false);
          const data = response.data.data.cms_payload_system;
          setPartners(JSON.parse(data.payload));
         
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getPartnersSection();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="mb-3" style={{display:"flex",justifyContent:"space-evenly"}}>
    <div> <div style={{paddingTop:"30px",paddingLeft:"50px"}} dangerouslySetInnerHTML={{ __html: partners.mainHeading }} />
          <div style={{paddingLeft:"50px"}}dangerouslySetInnerHTML={{ __html: partners.subHeading }} /></div>
         
          <div><img style={{paddingTop:"30px",paddingLeft:"400px"}} src={partnerImage} alt=""/></div>
        </div>
       
        <div></div>
        <div>

        </div>
      </div>

      <div></div>
    </div>
  );
};

export default States;
