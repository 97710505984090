import React from 'react'

const Return = () => {
  return (
    <div className='return'>Returns Policy
    <br/>If your product is damaged, defective, incorrect or incomplete at the time of delivery, please raise a return request on Daraz app or website.
    <br/> Return request must be raised within 14 days for DarazMall items, or within 7 days for non-DarazMall items from the date of delivery.
    <br/>
    For electronic appliances & mobile phones related issues after usage or after the return policy period, please check if the product is covered under seller warranty or brand warranty. For more information on warranty claims, please view our Warranty Policy.
    <br/>
    For selected categories, we accept a change of mind. Please refer to the section below on Return Policy per Category for more information.</div>
  )
}

export default Return