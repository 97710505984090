import React from 'react'

const Terms = () => {
  return (
    <div className='terms'>Terms and Conditions 
   <br/> <br/>Please read the Terms and Conditions carefully before using Daraz.com.np. 
    
   <br/><br/> This electronic record is generated by a computer system and does not require any physical or digital signatures. 
    
   <br/><br/>1. INTRODUCTION 
    Welcome to Daraz.com.np also hereby known as “we", "us" or "Daraz". We are an online marketplace and these are the terms and conditions governing your access and use of Daraz along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you hereby accept these terms and conditions (including the linked information herein) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This site is owned and operated by Daraz Kaymu Private Limited (Registration No.125263/71/72).
    
    <br/><br/>The Site reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes.</div>
  )
}

export default Terms