const Brands = () => {
  return (
    <div>
      <div
        className="brands"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          backgroundColor: "#383434",
          padding: "25px 0px",
        }}
      >
        <a href="blog.html" className="brand">
          <img src="assets/images/brands/5.png" alt="Brand Name" />
        </a>

        <a href="blog.html" className="brand">
          <img src="assets/images/brands/4.png" alt="Brand Name" />
        </a>

        <a href="blog.html" className="brand">
          <img src="assets/images/brands/3.png" alt="Brand Name" />
        </a>

        <a href="blog.html" className="brand">
          <img src="assets/images/brands/6.png" alt="Brand Name" />
        </a>
        <a href="blog.html" className="brand">
          <img src="assets/images/brands/4.png" alt="Brand Name" />
        </a>
        <a href="blog.html" className="brand">
          <img src="assets/images/brands/6.png" alt="Brand Name" />
        </a>

        <a href="blog.html" className="brand">
          <img src="assets/images/brands/7.png" alt="Brand Name" />
        </a>

        <a href="blog.html" className="brand">
          <img src="assets/images/brands/3.png" alt="Brand Name" />
        </a>
      </div>
    </div>
  );
};

export default Brands;
