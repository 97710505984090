import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
      <div>
        <footer
          className="footer footer-2"
          style={{ backgroundColor: "#383434", color: "white" }}
        >
          <div className="footer-middle">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-lg-4">
                  <div className="widget widget-about">
                    <img
                      src="assets/images/demos/demo-7/logo1.png"
                      className="footer-logo"
                      alt=""
                      width="105"
                      height="25"
                    />
                    <p>The Mobile Phone Shop. Retail Outlet, sincerely doing our part in providing customers Best Purchase for the Price they Pay!!</p>

                    <div className="widget-about-info">
                      <div className="row">
                        <div className="col-sm-6 col-md-4">
                          <span
                            style={{ color: "#fff" }}
                            className="widget-about-title"
                          >
                            Got Question? Call us 24/7
                          </span>
                          <a style={{ color: "#c76263" }} href="tel:123456789">
                            +0123 456 789
                          </a>
                        </div>
                        <div className="col-sm-6 col-md-8">
                          <span
                            style={{ color: "#fff" }}
                            className="widget-about-title"
                          >
                            Payment Method
                          </span>
                          <figure className="footer-payments">
                            <img
                              src="assets/images/payments.png"
                              alt="Payment methods"
                              width="272"
                              height="20"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-lg-2">
                  <div className="widget">
                    <h4 className="widget-title">Pages</h4>

                    <ul className="widget-list">
                      <li>
                        <Link to="/shoplist">Products</Link>
                      </li>
                      <li>
                        <a href="blog.html"><Link to="/contact">Contact Us</Link> </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>

                <div className="col-sm-4 col-lg-2">
                  <div className="widget">
                    <h4 className="widget-title">Legal Information</h4>

                    <ul className="widget-list">
                      <li>
                        <a href="blog.html"><Link to="/terms">Terms and Conditions</Link></a>
                      </li>
                      <li>
                        <a href="blog.html"><Link to="/privacy">Privacy Policy</Link></a>
                      </li>
                      <li>
                        <a href="blog.html"><Link to="/return">Return Policy</Link></a>
                      </li>
                      <li>
                        <a href="blog.html"><Link to="/refund">Refund Policy</Link></a>
                      </li>
                    
                    </ul>
                  </div>
                </div>

            
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container-fluid">
              <p className="footer-copyright">
                Copyright © 2023 Store. All Rights Reserved.
              </p>
              <ul className="footer-menu">
                <li>
                <Link to="/Terms">Terms Of Use</Link>
                </li>
                <li>
                <Link to="/Privacy">Privacy Policy</Link>
                </li>
              </ul>

              
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
