import React, { useEffect, useState } from "react";
import axios from "axios";
import { Product } from "../services/product.service";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import { MoonLoader } from "react-spinners";
import Banner from "../assets/images/about-header-bg.jpg";

// import baseURL from "../configuration/config";

const baseURL = "https://ea.allgetz.com/";
const api = "https://ea.allgetz.com/api/v1/site";

const Shoplist = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    getCategory();
    getBrand();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    getItems();
    // eslint-disable-next-line
  }, [page]);

  // const handleAddToCart = async (e) => {};

  async function getItems() {
    setLoading(true);
    const response = await Product.getProducts(8, page);
    setProducts(response.data);
    setLoading(false);
  }

  async function getCategory() {
    const response = await axios.get(api + "/all-categories");
    setCategory(response.data.data.categories);
  }
  async function getBrand() {
    const response = await axios.get(api + "/all-brands");
    setBrand(response.data.data.brands);
  }

  return (
    <div>
      <body>
        <div class="page-wrapper" className="category">
          <header class="header">
            <div class="header-top">
              <div class="container"></div>
            </div>
          </header>

          <main class="main">
            <div
              class="page-header text-center"
              style={{ backgroundImage: `url(${Banner})`, height: "220px" }}
            >
              <div class="container">
                <h1 class="page-title">
                  <span
                    style={{
                      color: "#fff",
                      paddingTop: "40px",
                      fontSize: "40px",
                    }}
                  >
                    Shop
                  </span>
                </h1>
              </div>
            </div>
            <nav aria-label="breadcrumb" class="breadcrumb-nav mb-2">
              <div class="container">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="index.html">
                      <Link to="/">Home</Link>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="blog.html">
                      <Link to="/shoplist">Shop</Link>
                    </a>
                  </li>
                </ol>
              </div>
            </nav>

            <div class="page-content">
              <div class="container">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="toolbox">
                      <div class="toolbox-left">
                        <div class="toolbox-info">
                          Showing <span>9 of 56</span> Products
                        </div>
                      </div>

                      <div class="toolbox-right">
                        <div class="toolbox-sort">
                          <label for="sortby">Sort by:</label>
                          <div class="select-custom">
                            <select
                              name="sortby"
                              id="sortby"
                              class="form-control"
                            >
                              <option value="popularity" selected="selected">
                                Most Popular
                              </option>
                              <option value="rating">Most Rated</option>
                              <option value="date">Date</option>
                            </select>
                          </div>
                        </div>
                        <div class="toolbox-layout">
                          <a href="category-list.html" class="btn-layout">
                            <svg width="16" height="10">
                              <rect x="0" y="0" width="4" height="4" />
                              <rect x="6" y="0" width="10" height="4" />
                              <rect x="0" y="6" width="4" height="4" />
                              <rect x="6" y="6" width="10" height="4" />
                            </svg>
                          </a>

                          <a href="category-2cols.html" class="btn-layout">
                            <svg width="10" height="10">
                              <rect x="0" y="0" width="4" height="4" />
                              <rect x="6" y="0" width="4" height="4" />
                              <rect x="0" y="6" width="4" height="4" />
                              <rect x="6" y="6" width="4" height="4" />
                            </svg>
                          </a>

                          <a href="category.html" class="btn-layout">
                            <svg width="16" height="10">
                              <rect x="0" y="0" width="4" height="4" />
                              <rect x="6" y="0" width="4" height="4" />
                              <rect x="12" y="0" width="4" height="4" />
                              <rect x="0" y="6" width="4" height="4" />
                              <rect x="6" y="6" width="4" height="4" />
                              <rect x="12" y="6" width="4" height="4" />
                            </svg>
                          </a>

                          <a
                            href="category-4cols.html"
                            class="btn-layout active"
                          >
                            <svg width="22" height="10">
                              <rect x="0" y="0" width="4" height="4" />
                              <rect x="6" y="0" width="4" height="4" />
                              <rect x="12" y="0" width="4" height="4" />
                              <rect x="18" y="0" width="4" height="4" />
                              <rect x="0" y="6" width="4" height="4" />
                              <rect x="6" y="6" width="4" height="4" />
                              <rect x="12" y="6" width="4" height="4" />
                              <rect x="18" y="6" width="4" height="4" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="products mb-3">
                      <div class="row justify-content-center">
                        {loading ? (
                          <MoonLoader color="rgba(211, 109, 77, 1)" />
                        ) : (
                          products?.products?.map((item) => (
                            <div
                              class="col-6 col-md-4 col-lg-4 col-xl-3"
                              key={item.id}
                            >
                              <div class="product product-7 text-center">
                                <figure class="product-media">
                                  {/* <span class="product-label label-new">
                                    New
                                  </span> */}

                                  <Link to={"/shop/" + item.id}>
                                    <img
                                      style={{
                                        height: "300px",
                                        objectFit: "contain",
                                      }}
                                      src={
                                        baseURL +
                                        "/uploads/products/" +
                                        item.image
                                      }
                                      alt=""
                                      class=""
                                    />
                                  </Link>

                                  <div class="product-action-vertical">
                                    <a
                                      href="blog.html"
                                      class="btn-product-icon btn-wishlist btn-expandable"
                                    >
                                      <span>add to wishlist</span>
                                    </a>
                                    <a
                                      href="popup/quickView.html"
                                      class="btn-product-icon btn-quickview"
                                      title="Quick view"
                                    >
                                      <span>Quick view</span>
                                    </a>
                                    <a
                                      href="blog.html"
                                      class="btn-product-icon btn-compare"
                                      title="Compare"
                                    >
                                      <span>Compare</span>
                                    </a>
                                  </div>

                                  <div class="product-action">
                                    <a
                                      href="blog.html"
                                      class="btn-product btn-cart"
                                      onClick={async (e) => {
                                        e.preventDefault();
                                       await axios.post(
                                          `${api}/cart/add-product`,
                                          { product_id: item.id, quantity: 1 },
                                          {
                                            headers: {
                                              "Authorization": "Bearer " + sessionStorage.getItem("loginToken")
                                            },
                                            
                                           
                                          }
                                          
                                        );
                                      }}
                                      
                                    >
                                 
                                      <span>Add To Cart</span>
                                    </a>
                                  </div>
                                </figure>

                                <div class="product-body">
                                  <div class="product-cat">
                                    <a href="blog.html">Bag</a>
                                  </div>
                                  <h3 class="product-title">
                                    <a href="product.html">{item.name}</a>
                                  </h3>
                                  <div class="product-price">
                                    Rs. {item.price}
                                  </div>
                                  {/* <div class="ratings-container">
                                    <div class="ratings">
                                      <div
                                        class="ratings-val"
                                        style={{ width: "20%" }}
                                      ></div>
                                    </div>
                                    <span class="ratings-text">
                                      ( 2 Reviews )
                                    </span>
                                  </div> */}

                                  <div class="product-nav product-nav-thumbs">
                                    <a href="blog.html" class="active">
                                      <img
                                        src={
                                          baseURL +
                                          "/uploads/products/" +
                                          item.image
                                        }
                                        alt=""
                                      />
                                    </a>
                                    <a href="blog.html">
                                      <img
                                        src="assets/images/products/product-4-2-thumb.jpg"
                                        alt="product desc"
                                      />
                                    </a>

                                    <a href="blog.html">
                                      <img
                                        src="assets/images/products/product-4-3-thumb.jpg"
                                        alt="product desc"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>

                    <Pagination
                      index={page}
                      setIndex={setPage}
                      totalData={products?.totalRows}
                    />
                  </div>
                  <aside class="col-lg-3 order-lg-first">
                    <div class="sidebar sidebar-shop">
                      <div class="widget widget-clean">
                        <label>Filters:</label>
                        <a href="blog.html" class="sidebar-filter-clear">
                          Clean All
                        </a>
                      </div>

                      <div class="widget widget-collapsible">
                        <h3 class="widget-title">
                          <a
                            data-toggle="collapse"
                            href="#widget-1"
                            role="button"
                            aria-expanded="true"
                            aria-controls="widget-1"
                          >
                            Category
                          </a>
                        </h3>

                        <div class="collapse show" id="widget-1">
                          <div class="widget-body">
                            <div class="filter-items filter-items-count">
                              {category.map((item, i) => {
                                return (
                                  <div class="filter-item">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="cat-1"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="cat-1"
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                    <span class="item-count">3</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="widget widget-collapsible">
                        <h3 class="widget-title">
                          <a
                            data-toggle="collapse"
                            href="#widget-4"
                            role="button"
                            aria-expanded="true"
                            aria-controls="widget-4"
                          >
                            Brand
                          </a>
                        </h3>

                        <div class="collapse show" id="widget-4">
                          <div class="widget-body">
                            <div class="filter-items">
                              <div class="filter-item">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="brand-1"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="brand-1"
                                  >
                                    Next
                                  </label>
                                </div>
                              </div>
                              {brand.map((item, i) => {
                                return (
                                  <div class="filter-item">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="brand-2"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="brand-2"
                                      >
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </main>
        </div>
        <button id="scroll-top" title="Back to Top">
          <i class="icon-arrow-up"></i>
        </button>

        <div class="mobile-menu-overlay"></div>

        <div class="mobile-menu-container">
          <div class="mobile-menu-wrapper">
            <span class="mobile-menu-close">
              <i class="icon-close"></i>
            </span>

            <form action="blog.html" method="get" class="mobile-search">
              <label for="mobile-search" class="sr-only">
                Search
              </label>
              <input
                type="search"
                class="form-control"
                name="mobile-search"
                id="mobile-search"
                placeholder="Search in..."
                required
              />
              <button class="btn btn-primary" type="submit">
                <i class="icon-search"></i>
              </button>
            </form>

            <nav class="mobile-nav">
              <ul class="mobile-menu">
                <li class="active">
                  <a href="index.html">Home</a>

                  <ul>
                    <li>
                      <a href="index-1.html">01 - furniture store</a>
                    </li>
                    <li>
                      <a href="index-2.html">02 - furniture store</a>
                    </li>
                    <li>
                      <a href="index-3.html">03 - electronic store</a>
                    </li>
                    <li>
                      <a href="index-4.html">04 - electronic store</a>
                    </li>
                    <li>
                      <a href="index-5.html">05 - fashion store</a>
                    </li>
                    <li>
                      <a href="index-6.html">06 - fashion store</a>
                    </li>
                    <li>
                      <a href="index-7.html">07 - fashion store</a>
                    </li>
                    <li>
                      <a href="index-8.html">08 - fashion store</a>
                    </li>
                    <li>
                      <a href="index-9.html">09 - fashion store</a>
                    </li>
                    <li>
                      <a href="index-10.html">10 - shoes store</a>
                    </li>
                    <li>
                      <a href="index-11.html">11 - furniture simple store</a>
                    </li>
                    <li>
                      <a href="index-12.html">12 - fashion simple store</a>
                    </li>
                    <li>
                      <a href="index-13.html">13 - market</a>
                    </li>
                    <li>
                      <a href="index-14.html">14 - market fullwidth</a>
                    </li>
                    <li>
                      <a href="index-15.html">15 - lookbook 1</a>
                    </li>
                    <li>
                      <a href="index-16.html">16 - lookbook 2</a>
                    </li>
                    <li>
                      <a href="index-17.html">17 - fashion store</a>
                    </li>
                    <li>
                      <a href="index-18.html">
                        18 - fashion store (with sidebar)
                      </a>
                    </li>
                    <li>
                      <a href="index-19.html">19 - games store</a>
                    </li>
                    <li>
                      <a href="index-20.html">20 - book store</a>
                    </li>
                    <li>
                      <a href="index-21.html">21 - sport store</a>
                    </li>
                    <li>
                      <a href="index-22.html">22 - tools store</a>
                    </li>
                    <li>
                      <a href="index-23.html">
                        23 - fashion left navigation store
                      </a>
                    </li>
                    <li>
                      <a href="index-24.html">24 - extreme sport store</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="category.html">Shop</a>
                  <ul>
                    <li>
                      <a href="category-list.html">Shop List</a>
                    </li>
                    <li>
                      <a href="category-2cols.html">Shop Grid 2 Columns</a>
                    </li>
                    <li>
                      <a href="category.html">Shop Grid 3 Columns</a>
                    </li>
                    <li>
                      <a href="category-4cols.html">Shop Grid 4 Columns</a>
                    </li>
                    <li>
                      <a href="category-boxed.html">
                        <span>
                          Shop Boxed No Sidebar
                          <span class="tip tip-hot">Hot</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="category-fullwidth.html">
                        Shop Fullwidth No Sidebar
                      </a>
                    </li>
                    <li>
                      <a href="product-category-boxed.html">
                        Product Category Boxed
                      </a>
                    </li>
                    <li>
                      <a href="product-category-fullwidth.html">
                        <span>
                          Product Category Fullwidth
                          <span class="tip tip-new">New</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="cart.html">Cart</a>
                    </li>
                    <li>
                      <a href="checkout.html">Checkout</a>
                    </li>
                    <li>
                      <a href="wishlist.html">Wishlist</a>
                    </li>
                    <li>
                      <a href="blog.html">Lookbook</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="product.html" class="sf-with-ul">
                    Product
                  </a>
                  <ul>
                    <li>
                      <a href="product.html">Default</a>
                    </li>
                    <li>
                      <a href="product-centered.html">Centered</a>
                    </li>
                    <li>
                      <a href="product-extended.html">
                        <span>
                          Extended Info<span class="tip tip-new">New</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="product-gallery.html">Gallery</a>
                    </li>
                    <li>
                      <a href="product-sticky.html">Sticky Info</a>
                    </li>
                    <li>
                      <a href="product-sidebar.html">Boxed With Sidebar</a>
                    </li>
                    <li>
                      <a href="product-fullwidth.html">Full Width</a>
                    </li>
                    <li>
                      <a href="product-masonry.html">Masonry Sticky Info</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="blog.html">Pages</a>
                  <ul>
                    <li>
                      <a href="about.html">About</a>

                      <ul>
                        <li>
                          <a href="about.html">About 01</a>
                        </li>
                        <li>
                          <a href="about-2.html">About 02</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>

                      <ul>
                        <li>
                          <a href="contact.html">Contact 01</a>
                        </li>
                        <li>
                          <a href="contact-2.html">Contact 02</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="login.html">Login</a>
                    </li>
                    <li>
                      <a href="faq.html">FAQs</a>
                    </li>
                    <li>
                      <a href="404.html">Error 404</a>
                    </li>
                    <li>
                      <a href="coming-soon.html">Coming Soon</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="blog.html">Blog</a>

                  <ul>
                    <li>
                      <a href="blog.html">Classic</a>
                    </li>
                    <li>
                      <a href="blog-listing.html">Listing</a>
                    </li>
                    <li>
                      <a href="blog.html">Grid</a>
                      <ul>
                        <li>
                          <a href="blog-grid-2cols.html">Grid 2 columns</a>
                        </li>
                        <li>
                          <a href="blog-grid-3cols.html">Grid 3 columns</a>
                        </li>
                        <li>
                          <a href="blog-grid-4cols.html">Grid 4 columns</a>
                        </li>
                        <li>
                          <a href="blog-grid-sidebar.html">Grid sidebar</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="blog.html">Masonry</a>
                      <ul>
                        <li>
                          <a href="blog-masonry-2cols.html">
                            Masonry 2 columns
                          </a>
                        </li>
                        <li>
                          <a href="blog-masonry-3cols.html">
                            Masonry 3 columns
                          </a>
                        </li>
                        <li>
                          <a href="blog-masonry-4cols.html">
                            Masonry 4 columns
                          </a>
                        </li>
                        <li>
                          <a href="blog-masonry-sidebar.html">
                            Masonry sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="blog.html">Mask</a>
                      <ul>
                        <li>
                          <a href="blog-mask-grid.html">Blog mask grid</a>
                        </li>
                        <li>
                          <a href="blog-mask-masonry.html">Blog mask masonry</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="blog.html">Single Post</a>
                      <ul>
                        <li>
                          <a href="single.html">Default with sidebar</a>
                        </li>
                        <li>
                          <a href="single-fullwidth.html">
                            Fullwidth no sidebar
                          </a>
                        </li>
                        <li>
                          <a href="single-fullwidth-sidebar.html">
                            Fullwidth with sidebar
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="elements-list.html">Elements</a>
                  <ul>
                    <li>
                      <a href="elements-products.html">Products</a>
                    </li>
                    <li>
                      <a href="elements-typography.html">Typography</a>
                    </li>
                    <li>
                      <a href="elements-titles.html">Titles</a>
                    </li>
                    <li>
                      <a href="elements-banners.html">Banners</a>
                    </li>
                    <li>
                      <a href="elements-product-category.html">
                        Product Category
                      </a>
                    </li>
                    <li>
                      <a href="elements-video-banners.html">Video Banners</a>
                    </li>
                    <li>
                      <a href="elements-buttons.html">Buttons</a>
                    </li>
                    <li>
                      <a href="elements-accordions.html">Accordions</a>
                    </li>
                    <li>
                      <a href="elements-tabs.html">Tabs</a>
                    </li>
                    <li>
                      <a href="elements-testimonials.html">Testimonials</a>
                    </li>
                    <li>
                      <a href="elements-blog-posts.html">Blog Posts</a>
                    </li>
                    <li>
                      <a href="elements-portfolio.html">Portfolio</a>
                    </li>
                    <li>
                      <a href="elements-cta.html">Call to Action</a>
                    </li>
                    <li>
                      <a href="elements-icon-boxes.html">Icon Boxes</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <div class="social-icons">
              <a
                href="blog.html"
                class="social-icon"
                target="_blank"
                title="Facebook"
              >
                <i class="icon-facebook-f"></i>
              </a>
              <a
                href="blog.html"
                class="social-icon"
                target="_blank"
                title="Twitter"
              >
                <i class="icon-twitter"></i>
              </a>
              <a
                href="blog.html"
                class="social-icon"
                target="_blank"
                title="Instagram"
              >
                <i class="icon-instagram"></i>
              </a>
              <a
                href="blog.html"
                class="social-icon"
                target="_blank"
                title="Youtube"
              >
                <i class="icon-youtube"></i>
              </a>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="signin-modal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i class="icon-close"></i>
                  </span>
                </button>

                <div class="form-box">
                  <div class="form-tab">
                    <ul class="nav nav-pills nav-fill" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="signin-tab"
                          data-toggle="tab"
                          href="#signin"
                          role="tab"
                          aria-controls="signin"
                          aria-selected="true"
                        >
                          Sign In
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="register-tab"
                          data-toggle="tab"
                          href="#register"
                          role="tab"
                          aria-controls="register"
                          aria-selected="false"
                        >
                          Register
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="tab-content-5">
                      <div
                        class="tab-pane fade show active"
                        id="signin"
                        role="tabpanel"
                        aria-labelledby="signin-tab"
                      >
                        <form action="#">
                          <div class="form-group">
                            <label for="singin-email">
                              Username or email address *
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="singin-email"
                              name="singin-email"
                              required
                            />
                          </div>

                          <div class="form-group">
                            <label for="singin-password">Password *</label>
                            <input
                              type="password"
                              class="form-control"
                              id="singin-password"
                              name="singin-password"
                              required
                            />
                          </div>

                          <div class="form-footer">
                            <button
                              type="submit"
                              class="btn btn-outline-primary-2"
                            >
                              <span>LOG IN</span>
                              <i class="icon-long-arrow-right"></i>
                            </button>

                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="signin-remember"
                              />
                              <label
                                class="custom-control-label"
                                for="signin-remember"
                              >
                                Remember Me
                              </label>
                            </div>

                            <a href="blog.html" class="forgot-link">
                              Forgot Your Password?
                            </a>
                          </div>
                        </form>
                        <div class="form-choice">
                          <p class="text-center">or sign in with</p>
                          <div class="row">
                            <div class="col-sm-6">
                              <a href="blog.html" class="btn btn-login btn-g">
                                <i class="icon-google"></i>
                                Login With Google
                              </a>
                            </div>
                            <div class="col-sm-6">
                              <a href="blog.html" class="btn btn-login btn-f">
                                <i class="icon-facebook-f"></i>
                                Login With Facebook
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="register"
                        role="tabpanel"
                        aria-labelledby="register-tab"
                      >
                        <form action="#">
                          <div class="form-group">
                            <label for="register-email">
                              Your email address *
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="register-email"
                              name="register-email"
                              required
                            />
                          </div>

                          <div class="form-group">
                            <label for="register-password">Password *</label>
                            <input
                              type="password"
                              class="form-control"
                              id="register-password"
                              name="register-password"
                              required
                            />
                          </div>

                          <div class="form-footer">
                            <button
                              type="submit"
                              class="btn btn-outline-primary-2"
                            >
                              <span>SIGN UP</span>
                              <i class="icon-long-arrow-right"></i>
                            </button>

                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="register-policy"
                                required
                              />
                              <label
                                class="custom-control-label"
                                for="register-policy"
                              >
                                I agree to the{" "}
                                <a href="blog.html">privacy policy</a> *
                              </label>
                            </div>
                          </div>
                        </form>
                        <div class="form-choice">
                          <p class="text-center">or sign in with</p>
                          <div class="row">
                            <div class="col-sm-6">
                              <a href="blog.html" class="btn btn-login btn-g">
                                <i class="icon-google"></i>
                                Login With Google
                              </a>
                            </div>
                            <div class="col-sm-6">
                              <a href="blog.html" class="btn btn-login  btn-f">
                                <i class="icon-facebook-f"></i>
                                Login With Facebook
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Shoplist;
