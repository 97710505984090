import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
const api = "https://ea.allgetz.com/api/v1/site";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  async function getBanners() {
    const response = await axios.get(api + "/all-banners");
    console.log(response);
    setBanners(response.data.data.banners);
  }
  useEffect(() => {
    getBanners();
  }, []);
  return (
    <div>
      <main className="main">
        <div className="container-fluid">
          <div className="row">
            {banners
              .map((item, i) => {
                return (
                  <div className="col-lg-6" key={i}>
                    <div className="banner banner-big banner-overlay">
                      <a href="blog.html">
                        <img
                          width="450px"
                          height="200px"
                          src={
                            "https://ea.allgetz.com/uploads/banners/" +
                            item.image
                          }
                          alt="Banner"
                        />
                      </a>

                      <div className="banner-content banner-content-center">
                        <h3 className="banner-subtitle text-white">
                          <a href="blog.html">{item.title}</a>
                        </h3>
                        <h2 className="banner-title text-white">
                          <a href="blog.html">{item.tag}</a>
                        </h2>
                        <a href="blog.html" className="btn underline">
                          <span>
                            <Link to="/shoplist">
                              <p style={{ color: "white" }}>Discover Now</p>
                            </Link>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
              .slice(0, 2)}
          </div>
          <div className="row justify-content-center">
            {banners
              .map((item, i) => {
                return (
                  <div className="col-md-6 col-lg-4" key={i}>
                    <div className="banner banner-overlay text-white">
                      <a href="blog.html">
                        <img alt=""
                          src={
                            "https://ea.allgetz.com/uploads/banners/" +
                            item.image
                          }
                          // src="assets/images/demos/demo-7/banners/banner-3.jpg"
                          // alt="Banner"
                        />
                      </a>

                      <div className="banner-content banner-content-right">
                        <h4 className="banner-subtitle">
                          <a href="blog.html">{item.title}</a>
                        </h4>
                        <h3 className="banner-title">
                          <a href="blog.html">
                            {item.tag}
                            <br />
                            sale -70% off
                          </a>
                        </h3>
                        <a
                          href="blog.html"
                          className="btn underline btn-outline-white-3 banner-link"
                        >
                          <span>
                            <Link to="/shoplist">
                              <p style={{ color: "white" }}>Shop Now</p>
                            </Link>
                          </span>
                        </a>
                      </div>
                    </div>
                    ;
                  </div>
                );
              })
              .slice(0, 3)}

            {/* <div className="col-md-6 col-lg-4">
              <div className="banner banner-overlay color-grey">
                <a href="blog.html">
                  <img
                    src="assets/images/demos/demo-7/banners/banner-4.jpg"
                    alt="Banner"
                  />
                </a>

                <div className="banner-content">
                  <h4 className="banner-subtitle">
                    <a href="blog.html">Accessories</a>
                  </h4>
                  <h3 className="banner-title">
                    <a href="blog.html">
                      2019 Winter
                      <br />
                      up to 50% off
                    </a>
                  </h3>
                  <a href="blog.html" className="btn underline banner-link">
                    <span>
                      {" "}
                      <Link to="/shoplist">
                        <p style={{ color: "white" }}>Shop Now</p>
                      </Link>
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="banner banner-overlay text-white">
                <a href="blog.html">
                  <img
                    src="assets/images/demos/demo-7/banners/banner-5.jpg"
                    alt="Banner"
                  />
                </a>

                <div className="banner-content banner-content-right mr">
                  <h4 className="banner-subtitle">
                    <a href="blog.html">New in</a>
                  </h4>
                  <h3 className="banner-title">
                    <a href="blog.html">
                      Women’s
                      <br />
                      sportswear
                    </a>
                  </h3>
                  <a
                    href="blog.html"
                    className="btn underline btn-outline-white-3 banner-link"
                  >
                    <span>
                      {" "}
                      <Link to="/shoplist">
                        <p style={{ color: "white" }}>Shop Now</p>
                      </Link>
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Banner;
