import axios from "axios";
import { useState, useEffect } from "react";
const api = "https://ea.allgetz.com/api/v1/site";

const NewArrivals = () => {
  const [arrivals, setArrivals] = useState([]);

  useEffect(() => {
    getArrivals();
  }, []);
  async function getArrivals() {
    const response = await axios.get(
      api + "/all-products?SortField=created_at"
    );
    setArrivals(response.data.data.products);
  }
  return (
    <div className="container-fluid">
      <div className="heading heading-center mb-3">
        <h2 className="title newArrivals">NEW ARRIVALS</h2>

        <ul className="nav nav-pills justify-content-center" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="new-women-link"
              data-toggle="tab"
              href="#new-women-tab"
              role="tab"
              aria-controls="new-women-tab"
              aria-selected="true"
            >
              Clothing
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="new-men-link"
              data-toggle="tab"
              href="#new-men-tab"
              role="tab"
              aria-controls="new-men-tab"
              aria-selected="false"
            >
              Mens Clothing
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-content">
        <div
          className="tab-pane p-0 fade show active"
          id="new-women-tab"
          role="tabpanel"
          aria-labelledby="new-women-link"
        >
          <div className="products">
            <div className="row justify-content-center">
              {arrivals?.map((item) => (
                <div className="col-6 col-md-4 col-lg-3 col-xl-5col">
                  <div className="product product-7 text-center">
                    <figure className="product-media">
                      {/* <span className="product-label label-circle label-sale">
          Sale
        </span> */}
                      <a href="product.html">
                        <img
                          src={
                            "https://ea.allgetz.com/uploads/products/" +
                            item.image
                          }
                          alt=""
                          className="product-image"
                          style={{ height: "300px", objectFit: "contain" }}
                        />
                        {/* <img
            src="assets/images/demos/demo-7/products/product-6-1.jpg"
            alt=""
            className="product-image-hover"
          /> */}
                      </a>

                      <div className="product-action-vertical">
                        <a
                          href="blog.html"
                          className="btn-product-icon btn-wishlist btn-expandable"
                        >
                          <span>add to wishlist</span>
                        </a>
                        <a
                          href="popup/quickView.html"
                          className="btn-product-icon btn-quickview"
                          title="Quick view"
                        >
                          <span>Quick view</span>
                        </a>
                      </div>

                      <div className="product-action">
                        <a href="blog.html" className="btn-product btn-cart">
                          <span>add to cart</span>
                        </a>
                      </div>
                    </figure>

                    <div className="product-body">
                      <h3 className="product-title">
                        <a href="product.html">{item.name}</a>
                      </h3>
                      <div className="product-price">
                        <span className="new-price">NPR {item.price}</span>
                        <span className="old-price">$84.00</span>
                      </div>
                      <div className="ratings-container">
                        <div className="ratings"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="tab-pane p-0 fade"
          id="new-men-tab"
          role="tabpanel"
          aria-labelledby="new-men-link"
        >
          <div className="products">
            <div className="row justify-content-center">
              
              {arrivals?.map((item) => (
                <div className="col-6 col-md-4 col-lg-3 col-xl-5col">
                  <div className="product product-7 text-center">
                    <figure className="product-media">
                      {/* <span className="product-label label-circle label-sale">
          Sale
        </span> */}
                      <a href="product.html">
                        <img
                          src={
                            "https://ea.allgetz.com/uploads/products/" +
                            item.image
                          }
                          alt=""
                          className="product-image"
                          style={{ height: "300px", objectFit: "contain" }}
                        />
                        {/* <img
            src="assets/images/demos/demo-7/products/product-6-1.jpg"
            alt=""
            className="product-image-hover"
          /> */}
                      </a>

                      <div className="product-action-vertical">
                        <a
                          href="blog.html"
                          className="btn-product-icon btn-wishlist btn-expandable"
                        >
                          <span>add to wishlist</span>
                        </a>
                        <a
                          href="popup/quickView.html"
                          className="btn-product-icon btn-quickview"
                          title="Quick view"
                        >
                          <span>Quick view</span>
                        </a>
                      </div>

                      <div className="product-action">
                        <a href="blog.html" className="btn-product btn-cart">
                          <span>add to cart</span>
                        </a>
                      </div>
                    </figure>

                    <div className="product-body">
                      <h3 className="product-title">
                        <a href="product.html">{item.name}</a>
                      </h3>
                      <div className="product-price">
                        <span className="new-price">NPR {item.price}</span>
                        <span className="old-price">$84.00</span>
                      </div>
                      <div className="ratings-container">
                        <div className="ratings"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="more-container text-center mt-2">
        <a href="blog.html" className="btn btn-outline-dark-3 btn-more">
          <span>Load more</span>
          <i className="icon-long-arrow-right" />
        </a>
      </div>
    </div>
  );
};
export default NewArrivals;
