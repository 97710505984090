import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../assets/images/bg-4.jpg";
const authURL = "https://ea.allgetz.com/api/v1/site";

const States = () => {
  const [statesData, setStatesData] = useState([]);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getStatesSection = () => {
      // setLoading(true);
      const name = "States";

      const formData = new FormData();
      formData.append("name", name);
      axios
        .post(authURL + "/cms-payload-system/get", formData)
        .then((response) => {
          console.log(response, "States");
          // setLoading(false);
          const data = JSON.parse(
            response.data.data.cms_payload_system.payload
          );

          setStatesData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getStatesSection();
  }, []);
  console.log("abc", statesData);

  return (
    <div>
      <div>
      <div className="row"
          style={{
            backgroundImage: `url(${Banner})`,
            width: "1600px",
            height: "280px",
            paddingTop:"100px",
             display:"flex",
             justifyContent:"space-evenly",
             
          }} >
      
      
        {statesData.stateItem?.map((item,index)=>{
          
return <div> <h5 style={{alignSelf: "center",color:"white",paddingLeft:"45px"}} key={index}>{item.stateItemSection.number}</h5>
<h5 style={{color:"white"}} key={index}>{item.stateItemSection.title}</h5>
</div>

        })
      }
     
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default States;
