const Features = () => {
  return (
    <div>
      <div
        className="icon-boxes-container"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-8 col-12 icon-boxes"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="col-sm-6 col-lg-4">
                <div className="icon-box icon-box-side">
                  <span className="icon-box-icon">
                    <i className="icon-truck"></i>
                  </span>

                  <div className="icon-box-content">
                    <h3 className="icon-box-title">Payment Delivery</h3>
                    <p>Free shipping for orders over $50</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="icon-box icon-box-side">
                  <span className="icon-box-icon">
                    <i className="icon-rotate-left"></i>
                  </span>

                  <div className="icon-box-content">
                    <h3 className="icon-box-title">Return Refund</h3>
                    <p>Free 100% money back guarantee</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4">
                <div className="icon-box icon-box-side">
                  <span className="icon-box-icon">
                    <i className="icon-headphones"></i>
                  </span>

                  <div className="icon-box-content">
                    <h3 className="icon-box-title">Quality Support</h3>
                    <p>Alway online feedback 24/7</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
