import React, { useState, useEffect } from "react";
import axios from "axios";
const authURL = "https://ea.allgetz.com/api/v1/site";
const Feature = () => {
  const [featureData, setFeatureData] = useState();
  // const [loading, setLoading] = useState(false);
  const getFeatureSection = async () => {
    // setLoading(true);
    const name = "Feature Section";

    const formData = new FormData();
    formData.append("name", name);
    const response = await axios.post(
      authURL + "/cms-payload-system/get",
      formData
    ).then(res => res.data.data.cms_payload_system.payload);
    setFeatureData(JSON.parse(response).featureItem);
  };
  useEffect(() => {
    getFeatureSection();
  }, []);
  return (
    <div>
      <div className="row">
        {featureData?.map((item) => (
          <div className="col-lg-4 mt-4 pb-2">
            <h2 className="title">{item.featureItemSection.title}</h2>
            <div dangerouslySetInnerHTML={{__html: item.featureItemSection.description}}/>
            <p></p>
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default Feature;
