import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Mission from "../components/Mission";
import Who from "../components/Who";
import Feature from "../components/Feature";
import States from "../components/States";
import Partners from "../components/Partners";
import Banner from "../assets/images/about-header-bg.jpg";

const authURL = "https://ea.allgetz.com/api/v1/site";

const About = () => {
  const [ setAboutData] = useState([]);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAboutSection = () => {
      // setLoading(true);
      const names = ["About", "Feature Section", "States", "Partners", "Who"];
      const formData = new FormData();
      names.forEach((name) => {
        formData.append("names[Mission]", name);
      });
      axios
        .post(authURL + "/cms-payload-system/get", formData)
        .then((response) => {
          // setLoading(false);
          const data = response.data.data.cms_payload_system;

          setAboutData([
            data.find((item) => item.name === "About"),
            data.find((item) => item.name === "Feature Section"),
            data.find((item) => item.name === "States"),
            data.find((item) => item.name === "Partners"),
            data.find((item) => item.name === "Who"),
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getAboutSection();
  }, [setAboutData]);

  return (
    <div>
      <div className="page-wrapper">
        <header className="header">
          <div className="header-top">
            <div className="container"></div>
          </div>
        </header>

        <main className="main">
          <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">
                    <Link to="/">Home</Link>
                  </a>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  <Link to="/about">About</Link>
                </li>
              </ol>
            </div>
          </nav>
          <div className="container">
            <div
              className="page-header page-header-big text-center"
              style={{ backgroundImage: `url(${Banner})` }}
            >
              <h1 className="page-title text-white">
                About us<span className="text-white">Who we are</span>
              </h1>
            </div>
          </div>

          <div className="page-content pb-0">
            <div className="container">
              <div>
                <Mission />
              </div>

              <div>
                <Who />
              </div>

              <div>
                <Feature />
              </div>

              <div>
                <States />
              </div>

              <div>
                <Partners />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default About;
