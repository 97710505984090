import React, { useState, useEffect } from "react";
import axios from "axios";
// import API from "../../configuration/config";
// import Loading from "../Elements/Loading";
const authURL = "https://ea.allgetz.com/api/v1/site";

const Privacy = () => {
  const [privacyData, setPrivacyData] = useState("");
  // const page = "Privacy-Policy";
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = () => {
      // setLoading(true);
      axios.get(authURL + "/page/single/")
        .then((response) => {
          console.log(response);
          const data = JSON.parse(response.data.data.page_info.page_data);
          // setLoading(false);
          setPrivacyData(data);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    };
    getData();
  }, []);

  return (
    <div>
      <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/privacy">Privacy policy</a>
            </li>
          </ol>
        </div>
        {/* End .container */}
      </nav>
      <div className="page-content">
       
          <div className="container">
            <h2 className="mb-3">Privacy Policy</h2>
            <div dangerouslySetInnerHTML={{ __html: privacyData }}></div>
          </div>
       
      </div>
    </div>
  );
};

export default Privacy;
