import { Link } from 'react-router-dom';
const MyTopBar = () => {
  return (
    <div className="page-wrapper">
      <header className="header header-7">
        <div className="header-top">
          <div className="container-fluid topBar">
            

            <div className="header-right">
              <ul className="top-menu">
                <li>
                  <a href="blog.html">Links</a>
                  <ul>
                    <li>
                      <a href="tel:#">
                        <i className="icon-phone"></i>Call: +0123 456 789
                      </a>
                    </li>
                    <li>
                     
                        <i className="icon-heart-o"></i> <Link to="/WishlistSection">Wishlist</Link>
                        <span>(3)</span>
                      
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                     <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <a href="#modal" data-toggle="modal">
                        <i className="icon-user"></i>
                        
                        <Link to="/register">Login</Link>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default MyTopBar;
