import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Layout from "./pages/Layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Shop from "./pages/Shop";
import Log from "./pages/Log";
import Cart from "./pages/Cart";
import Wishlist from "./pages/Wishlist";
import Faq from "./pages/Faq";
// import Checkout from "./pages/Checkout";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Return from "./pages/Return";
import Refund from "./pages/Refund";
import Shoplist from "./pages/Shoplist";
import Register from "./pages/Register";
import { ThemeProvider } from "./context/ThemeProvider";
// import AccountDetails from "./components/AccountsDetails";
// import { AccordianData } from "./Data/AccordianData";
import WishlistSection from "./components/WishlistSection";
import Team from "./components/Team";
import CartDetails from "./components/CartDetails";
import Accounts from "./components/Accounts";
import EditBilling from "./components/EditBilling";
import CheckoutDetails from "./components/Checkout/CheckoutDetails";
// import PasswordReset from "./components/PasswordResetPage/PasswordReset";

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/shop/:id" element={<Shop />} />
              <Route path="/log" element={<Log />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/Terms" element={<Terms />} />
              <Route path="/return" element={<Return />} />
              <Route path="/refund" element={<Refund />} />
              <Route path="/checkout" element={<CheckoutDetails />} />
              <Route path="/shoplist" element={<Shoplist />} />
              <Route path="/register" element={<Register />} />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/editbilling" element={<EditBilling />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/wishlistsection" element={<WishlistSection />} />
              <Route path="/team" element={<Team />} />
              <Route path="cartdetails" element={<CartDetails />} />
           
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
